
.business-unit-page {

  .header {
    h2 {
      margin-bottom: 1rem;
    }
  }

  .business-unit-meta {
    font-size: 1rem;
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    strong {
      margin-right: 0.3rem;
    }

    .product-management-vp::after {
      content: '·';
      margin-left: 1rem;
    }
  }

  .stat {
    display: flex;
    align-items: baseline;
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    text-decoration: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid #EEE;

    &:first-child {
      border-top: 1px solid #EEE;
    }

    .stat-number {
      font-weight: bold;
      font-size: 20px;
      margin-right: 1rem;
      min-width: 80px;
      display: inline-block;
      text-align: right;
    }

    .stat-text {
      margin-right: 10px;
    }
  }

  .download-note {
    font-size: 0.875rem;
    font-style: italic;
    margin-bottom: 1rem;
  }

  .download-links {
    li {
      margin-bottom: 10px;
    }

    svg {
      vertical-align: -2px;
    }
  }

  .product-table-options {
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.5rem;
  }

  table {
    // Hack so that divs can expand to full cell height
    height: 1px;

    tr {
      height: auto;
    }

    td {
      padding: 0;
      text-align: center;
    }

    th .bx--table-sort {
      padding-left: 0;
    }

    th .bx--table-sort__flex {
      padding-inline: 2rem;
      justify-content: center;
      text-align: center;
      position: relative;

      svg {
        position: absolute;
        right: 0;
      }
    }

    .utitem-row {
      font-weight: 500;
    }

    .product-container {
      display: flex;
      align-items: center;
      margin-left: 25px;

      button {
        margin-left: -20px;
        position: absolute;
      }

      a {
        flex-grow: 1;
        padding-left: 5px;
      }
    }

    .expand-btn {
      display: inline-block;
      background: none;
      border: none;
      padding: 2px;
      line-height: 0;
      cursor: pointer;
      margin-right: 2px;
      margin-left: 2px;
    }

    .expand-btn:hover {
      background-color: rgba(0,0,0,0.1);
      border-radius: 100%;
    }

    .depth-1 .col-product { padding-left: 20px; }
    .depth-2 .col-product { padding-left: 40px; }
    .depth-3 .col-product { padding-left: 60px; }
    .depth-4 .col-product { padding-left: 80px; }

    .depth-0 td { background-color: #ddd }
    .depth-1 td { background-color: #e5e5e5 }
    .depth-2 td { background-color: #eee }
    .depth-3 td { background-color: #f6f6f6 }
    .depth-4 td { background-color: #fff }

    tr:hover td { background-color: #cfdffe !important; }

    .col-product {
      text-align: left;

      .bx--table-sort__flex {
        justify-content: left;
      }
    }

    th.col-product, td.col-product, th.col-sla_compliance_percent, td.col-sla_compliance_percent {
      border-right: 1px solid #ccc;
    }

    .sla-compliance {
      height: 100%;
      padding: 0.3rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      display: block;
      text-decoration: none;
      color: inherit;
      padding: 0.3rem 1rem;

      &:hover {
        background-color: rgba(0,0,0,0.07);
      }
    }
  }

  .sla-good { background-color: #dff0d8; }
  .sla-warn { background-color: #fcf8e3; }
  .sla-bad  { background-color: #f8d7da; }
}
