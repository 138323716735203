.shell-header {

  // Always show navs, even on small screens
  .left-nav, .right-nav {
    display: block;
  }

  .user-name {
    margin-right: 0.5rem;
  }

  .down-arrow {
    margin-left: 0.5rem;
  }

  // Right align profile menu dropdown
  .profile-menu .bx--header__menu-title[aria-expanded=true] + .bx--header__menu {
    left: auto;
    right: 0;
    width: 250px;
  }

  .profile-menu svg {
    vertical-align: -3px;
    margin-right: 3px;
  }

  .signed-in-as-item a {
    height: 4rem;

    .user-email {
      font-weight: bold;
    }
  }

}
