.idea-status-by-assignees-card {

    .checkboxes {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .defaultAssigneeNotice {
        text-align: right;
        font-size: 0.75rem;
    }
}