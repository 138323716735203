.product-list-selection {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  label {
    margin-right: 0.5rem;
  }

  .bx--dropdown {
    min-width: 200px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #8d8d8d;
  }
}

.create-product-list-modal {
  .error-message {
    color: red;
    padding: 1rem 0;
  }
}