@import './idea-status-by-assignees-card';
@import './customer-chart-card';

.summary-tab {

  .stat {
    display: flex;
    align-items: baseline;
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    text-decoration: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid #EEE;

    &:first-child {
      border-top: 1px solid #EEE;
    }

    .stat-number {
      font-weight: bold;
      font-size: 20px;
      margin-right: 1rem;
      min-width: 60px;
      display: inline-block;
      text-align: right;
    }

    .stat-text {
      margin-right: 10px;
    }

    &:hover {
      background-color: #f4f4f4;
    }
  }
}