.support-page {

  .card {
    max-width: 1000px;
  }

  p {
    margin-bottom: 1rem;
  }

  .header {
    padding-bottom: 2rem;
  }

  .link-list {
    margin-top: 2rem;

    li {
      margin-bottom: 1rem;
    }

    svg {
      vertical-align: -7px;
      margin-right: 0.5rem;
    }
  }

}