.product-dropdown {
  width: 700px;
  max-width: 100%;

  .product-option {
    display: flex;

    .product-reference {
      min-width: 100px;
    }

    .product-name {
      margin-left: 0.5rem;
    }
  }
}