@import 'carbon-components/scss/globals/scss/vars.scss';

@import './product-dropdown';
@import './product-list-selection';

.settings-page {

  .header {
    padding-bottom: 2rem;
  }

  .product-table {
    min-width: 800px;
  }

  th {
    font-weight: bold;
    text-align: left;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
  }

  tr:hover td {
    background-color: #f6f6f6;
  }

  td:not(:last-child) {
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .col-product-ref { width: 100px; }
  .col-product-cat { width: 200px; }
  .col-remove-button { width: 30px; text-align: right; }

  .remove-button {
    vertical-align: middle;
  }

  .add-product-section {
    margin-top: 2rem;
    label {
      display: inline-block;
      font-size: 15px;
    }

    .add-new-product {
      margin-bottom: 1rem;
    }

    .form-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1rem;
      gap: 1rem;
    }

    .category-dropdown {
      width: 30rem;
    }
  }

  .add-product-container {
    display: flex;
    width: 250px;
  }

  .error-message {
    color: red;
    padding: 1rem 0;
  }

  .sync-info {
    line-height: 1.5;
    margin-top: 2.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #ddd;
  }

  .sync-btn-container {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;

    .sync-btn {
      margin-right: 1rem;
    }

    .sync-message-icon {
      vertical-align: -2px;

      &.icon-success { color: $support-02; }
      &.icon-failed  { color: $support-01; }
    }
  }

  .my-warning-periods {
    .inline-field {
      display: flex;
      margin-bottom: 0.5rem;

      .bx--text-input-wrapper {
        min-width: 24rem;
        flex-grow: 0;
      }
      .error-message {
        flex-grow: 1;
        padding: 0.8125rem 0 0 1rem;
      }
    }

    .bx--text-input__label-helper-wrapper {
      max-width: 20rem;
    }

    .bx--text-input__field-outer-wrapper--inline {
      max-width: 5rem;
    }
  }

  .my-notifications {

    .slack-buttons-container {
      display: flex;
      margin-bottom: 2rem;
    }

    .slack-connect-button-container {
      min-width: 300px;
      display: inline-block;

      button svg {
        margin-right: 0.5rem;
      }
    }

    table th {
      font-weight: bold;
      text-align: center;
      padding: 0.5rem;
    }

    table td {
      vertical-align: middle;
      padding: 0.5rem 1rem;
    }

    .col-notification-checkbox {
      label {
        margin: 0 auto;

        .bx--checkbox-label-text {
          padding-left: 0;
        }
      }
    }
  }
}
