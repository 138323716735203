.shell-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 2rem;
  color: #666666;

  p {
    font-size: inherit;
  }

  ul {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}