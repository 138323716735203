.ideas-listing {

  .filters {
    display: flex;
    flex-wrap: wrap;

    .bx--form-item {
      flex-grow: 0;
    }

    .filter {
      width: 270px;
      margin-right: 1rem;
      margin-bottom: 1rem;

      &.filter-checkbox {
        margin-top: 2rem;
      }
    }
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition + .bx--assistive-text {
    display: inline-block;
  }

  .customer-header {
    svg {
      margin-left: 5px;
    }
    &::after {
      width: 13rem;
      padding: 1rem;
      white-space: normal;
      height: auto;
    }
  }

  .bx--tooltip__trigger:not(.bx--btn--icon-only) {
    font-size: 0.9rem;
  }

  .default-assignee {
    color: #161616;

  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    font-size: 0.9rem;
  }

  .overdue { color: red; }
  .coming-overdue { color: orange; }
  .actionable { color: #888; }

  .overdue-row {
    border-left: 5px solid red;
  }
  .coming-overdue-row {
    border-left: 5px solid orange;
  }

  .col-idea_reference { white-space: nowrap; }
  .col-product { white-space: nowrap; }
  .col-category_list { min-width: 200px; }
  .col-name { min-width: 500px; }

  .col-raised_at { white-space: nowrap; }
  .col-last_updated_at { white-space: nowrap; }

  .bx--data-table thead th.bx--table-column-checkbox,
  .bx--data-table tbody td.bx--table-column-checkbox {
    width: 2.5rem;
    min-width: 2.5rem;
  }

  .defaultAssigneeNotice {
    font-size: 10px;
    text-align: right;
    padding-top: 5px;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  .assigneeInfoLeftIBM {
    color: red;
  }

  td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}