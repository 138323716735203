.bulk-update-modal {

  .bx--modal-container {
    overflow-y: auto;
  }

  p {
    margin-bottom: 1rem;
  }

  .idea-ref {
    white-space: nowrap;
  }

  .update-field {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    .enable-update-field {
      margin-bottom: 0.5rem;
    }
  }

  .bx--dropdown__wrapper {
    margin-bottom: 1rem;
  }

  .bx--text-input:disabled {
    background-color: #ffffff;
  }

  .comment-textarea {
    height: 150px;
  }

  .error-message {
    color: red;
  }

  .submit-container {
    flex: 0 1 50%;

    .submit-loading {
      padding-top: 1rem;
      margin-left: 1rem;
    }
  }
}
