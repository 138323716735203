@import 'carbon-components/scss/globals/scss/vars.scss';

@import './components/components';
@import './pages/IdeasPage/ideas-page';
@import './pages/BusinessUnitSummaryPage/business-unit-summary-page';
@import './pages/BusinessUnitPage/business-unit-page';
@import './pages/SettingsPage/settings-page';
@import './pages/SupportPage/support-page';

body {
  background-color: $ui-01;
}

.logging-in {
  text-align: center;
  margin: 8rem 2rem;

  .bx--inline-loading {
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.no-data-for-chart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .bx--inline-loading {
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.header {
  position: relative;
  padding-top: 2rem;
  margin-bottom: 2rem;

  &::before {
    content: '';
    position: absolute;
    left: -$spacing-06;
    top: 0;
    right: -$spacing-06;
    bottom: 0;
    background-color: white;
    z-index: -1;
  }

  .bx--tabs {
    margin-bottom: 0;
  }
}

.bx--content {
  background-color: transparent;
  padding: 0;
}

.card {
  background-color: white;
  margin-bottom: 2rem;

  .card-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid $ui-03;
    font-weight: bold;
    font-size: 16px;
  }

  .card-body {
    padding: 1rem;
  }
}
