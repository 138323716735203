@import 'carbon-components/scss/globals/scss/vars.scss';

@import './ListingsTabs/ideas-listing';
@import './ListingsTabs/similar-ideas-modal';
@import './ListingsTabs/bulk-update-modal';
@import './ListingsTabs/mark-as-spam-modal';
@import './SummaryTab/summary-tab';


.ideas-page {

  .dataDate{
    padding-bottom: 20px;
    text-align: right;
  }

  .header {
    h2 {
      margin-bottom: 0.5rem;
    }

    .bx--dropdown__wrapper {
      vertical-align: middle;
    }

    .product-list-dropdown {
      background-color: #f4f4f4;
      border-bottom: 1px solid #8d8d8d;
      margin-left: 0.5rem;
      min-width: 200px;
    }

    h3 {
      font-size: 17px;
      margin-bottom: 1rem;

      span {
        margin-left: 0.5rem;
      }
    }

    h3.selected-products {
      display: flex;
      align-items: baseline;

      strong {
        margin-right: 0.5rem;
      }

      button {
        margin-left: 0.5rem;
      }
    }

    .error-message {
      color: red;
    }
  }

  h4 {
    margin-bottom: 0.5rem;
  }

}

.selected-products-modal {
  .selected-products-modal-body {
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
}