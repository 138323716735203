.open-similar-ideas-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  vertical-align: middle;
}

.similar-ideas-modal {
  .modal-description {
    margin-bottom: 1rem;
  }

  .this-idea {
    font-weight: bold;
  }

  .idea-description {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  

  .merge-ideas-button-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    .reset-ideas-button {
      margin-right: 1rem;
    }

    .bx--inline-loading {
      flex-basis: 0;
      height: 48px;
    }

    .submit-ideas-button, .bx--inline-loading, .reset-ideas-button {
      width: 6rem;
    }
  } 

  .error-message {
    color: red;
  }
}

/* Fix extra 1px scrollbar issue */
tr.bx--parent-row.bx--expandable-row td.bx--table-expand + td::after {
  display: none;
}