.trend-chart-card {

  &.card .card-header {
    display: flex;
    align-items: center;
    padding-block: 0.5rem;

    span {
      margin-right: 0.5rem;
    }
  }
}