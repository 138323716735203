.mark-as-spam-modal {

  ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  .disable-portal-user {
    margin-bottom: 1rem;
  }

  .error-message {
    color: red;
  }

  .submit-container {
    flex: 0 1 50%;

    .submit-loading {
      padding-top: 1rem;
      margin-left: 1rem;
    }
  }
}