@import 'carbon-components/scss/globals/scss/vars.scss';

.business-unit-summary-page {

  .header {
    padding-bottom: 2rem;

    h2 {
      margin-bottom: 0.5rem;
    }

    .comparison-period {
      display: flex;
      align-items: center;
    }

    .comparison-period-dropdown {
      background-color: #f4f4f4;
      border-bottom: 1px solid #8d8d8d;
      margin-left: 0.5rem;
      min-width: 200px;
    }
  }

  table {
    width: 100%;
  }

  table th {
    font-weight: bold;
  }

  table th, table td {
    padding: 0.6rem 1rem;
    text-align: center;
    border-bottom: 1px solid #eee;
    vertical-align: middle;
    border-right: 1px solid #ddd;
  }

  td.col-business-unit {
    font-weight: 500;
  }

  .col-business-unit, .col-product-management-vp, .col-ideas-champion {
    text-align: left;
  }

  .col-product-management-vp, table td:last-child, table th:last-child {
    border-right: none;
  }

  table tr:nth-child(odd) td {
    background-color: #f8f8f8;
  }

  .number-delta-container {
    display: flex;

    div {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  table tr td.sla-good { background-color: #dff0d8; }
  table tr td.sla-warn { background-color: #fcf8e3; }
  table tr td.sla-bad  { background-color: #f8d7da; }
}
